::-webkit-scrollbar {
    width: 0;
}

html {
    font-size: 16px !important;
    min-height: 100vh;
    body {
        background-color: #f2f2f2;
        font-family: "Ubuntu", sans-serif;
        font-weight: 400;
        font-style: normal;
        .navbar {
            .navbarLogo {
                width: 10rem;
            }
        }
        header {
            min-height: 100vh;
            margin: 0;
            --x: calc(var(--posX, 0) * 1px);
            --y: calc(var(--posY, 0) * 1px);
            background-image: 
                linear-gradient(115deg, rgb(255, 0, 0), rgb(0, 0, 0)), 
                radial-gradient(90% 100% at calc(50% + var(--x)) calc(0% + var(--y)), rgb(60, 0, 2), rgba(50, 0, 50, 0.241)), 
                radial-gradient(100% 100% at calc(80% - var(--x)) calc(0% - var(--y)), rgb(200, 0, 85), rgb(116, 0, 17)), 
                radial-gradient(150% 230% at calc(100% + var(--x)) calc(0% + var(--y)), rgb(255, 0, 0), rgb(91, 0, 51)), 
                linear-gradient(60deg, rgb(255, 0, 0), rgb(200, 0, 200));
                background-blend-mode: overlay, overlay, difference, difference, difference, normal;
            }
        }
        @media only screen and (min-width: 768px) {
            header {
                background-image: url("./assets/BG.jpg") !important;
                background-size: cover !important; 
                background-position: center !important;
        }
    }
}
.bg-blur {
    backdrop-filter: blur(40px);
}

.bg-accent {
    background-color: #a42626 !important;
}

.accent {
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
    font-style: normal;
}
.bg-accent-mobile {
    background-color: #a42626 !important;
}
.w-max {
    width: max-content !important;
}
.headerBreak {
    background-image: linear-gradient(transparent, #17191c);
}
.readability {
    line-height: 2rem;
}
.no-string-break {
    text-wrap: nowrap;
}
.textc-black {
    color: #0b0b0b !important;
}
.textc-accent {
    color: #a42626 !important;
}
.bgc-black {
    background-color: #0b0b0b !important;
}
.custom-radio .form-check-input:checked {
    background-color: #a42626;
    border-color: #a42626;
}
.custom-radio .form-check-input {
    border-radius: 50%;
}
.custom-radio .form-check-input:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(164, 38, 38, 0.25); /* Customize with your preferred accent color */
}